@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.container-modal {
    a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        background-color: #4eacbf;
        border-color: rgba(0, 0, 0, 0.05);
        height: 40px;
        margin-top: 0px;
        margin-left: 10px;
        padding: 0px 25px;
        transition: 0.2s;
        text-decoration: none;
        font-family: 'Titillium Web', sans-serif;

        @include sm {
            margin-left: 0px;
            margin-top: 10px;
        }

        &:hover {
            cursor: pointer;
            background-color: #3e8a99;
            transition: 0.2s;
        }
    }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #3e8a99;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
