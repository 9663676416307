.image-grid {
    position: relative;
    margin-bottom: 10px;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .play-button {
        position: absolute;
        top: 40%;
        left: 40%;
        width: 20%;
        transition: 0.2s;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
        transition: 0.2s;
    }
}
