.button-share-touchpix {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    background-color: var(--background-button);
    transition: 0.2s;
    border-radius: var(--radious-button);

    &:hover,
    &:active {
        cursor: pointer;
        filter: brightness(75%);
        transition: 0.2s;
    }

    img,
    svg {
        width: 24px;
        height: auto;
    }
}

#popover-email {
    z-index: 9999;
}

.popover-content {
    position: fixed;
    background-color: #ccc;
    padding: 15px;
    border-radius: 4px;
    top: 0px;
    left: -300px;
    font-family: Arial, Helvetica, sans-serif;
    //animation-name: transition;
    //animation-duration: 4s;
    transition: 0.8s;

    .popover-message {
        margin: 0;
    }

    @keyframes transition {
        0% {
            left: -300px;
        }
        20% {
            left: 0px;
        }
        80% {
            left: 0px;
        }
        100% {
            left: -300px;
        }
    }
}

.spinner {
    border: 2px solid #000000;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: #d3d3d3;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
