:root {
    --background-button: #d3d3d3;
    --color-icon: #000000;
    --radious-button: 0px;
}

body {
    margin: 0;
}

.App {
    min-height: 100vh;
    padding-top: 50px;
    background-color: #232323;

    .container-logo {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 150px;
        }
    }

    .container {
        padding: 10px;
    }

    .text-wrong-gallery {
        text-align: center;
        color: #545454;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.7rem;
        font-weight: 200;
    }

    .container-top-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-right: -5px;
        margin-left: -5px;
        margin-bottom: 10px;

        .container-switch {
            .reverse-gallery {
                background-color: #808080;
            }
        }
    }

    .selector-overlay {
        position: relative;
        z-index: 99;
    }
}
