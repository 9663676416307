@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

.modal_email {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.9) !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-modal.close {
        top: 20px;
        right: 20px;

        @include sm {
            width: 100vw;
            height: 60px;
            top: 0;
            right: 0;
            background-color: #4eacbf;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .close-sm {
            display: none;

            @include sm {
                display: inline;
                font-size: 1.6rem;
                color: #ffffff;
                margin-left: 10px;
            }
        }

        img {
            filter: brightness(2);
        }
    }

    .container-modal-email {
        width: auto;
        height: auto;
        background-color: #ffffff;
        padding: 3rem;
        border-radius: 7px;

        @include sm {
            padding: 1.5rem;
        }

        .container-modal {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        h1 {
            font-family: 'Titillium Web', sans-serif;
            font-weight: 300;
            font-size: 1.7em;
            line-height: 1.3;
            color: #555;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0.5em;
            text-rendering: optimizeSpeed;
        }

        p {
            font-family: Arial, Helvetica, sans-serif;
            color: #777;
            -moz-osx-font-smoothing: grayscale;
            margin-bottom: 1.3em;
        }

        form {
            display: flex;

            @include sm {
                flex-direction: column;
            }
        }

        input {
            display: inline-block;
            box-sizing: border-box;
            box-shadow: inset 0 -1.8em 1em 0 transparent;
            height: 40px;
            padding: 5px;
            font-size: 1rem;
            border: solid 1px rgba(66, 66, 66, 0.2);
            box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);
            text-shadow: 0px 0px 5px rgba(66, 66, 66, 0.2);

            &:focus {
                outline: none;
            }
        }

        button {
            box-sizing: border-box;
            color: #fff;
            background-color: #4eacbf;
            border-color: rgba(0, 0, 0, 0.05);
            height: 40px;
            margin-top: 0px;
            margin-left: 10px;
            padding: 0px 25px;
            transition: 0.2s;

            @include sm {
                margin-left: 0px;
                margin-top: 10px;
            }

            &:hover {
                cursor: pointer;
                background-color: #3e8a99;
                transition: 0.2s;
            }
        }
    }
}
