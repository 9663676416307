.ReactModal__Content {
    position: absolute !important;
    inset: 0 !important;
    border: none !important;
    background: #fff !important;
    overflow: auto !important;
    border-radius: 0px !important;
    outline: none !important;
    padding: 0px !important;
    height: 100vh !important;

    .modal-content {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include sm {
            justify-content: flex-start;
        }

        .uploading-file {
            margin: 40px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                animation: spin 2s linear infinite;
            }

            p {
                max-width: 300px;
                margin: 30px 10px;
                text-align: center;
                color: #545454;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1.5rem;
                font-weight: 200;
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    .media-content {
        height: auto;
        max-width: 80vw;
        max-height: 80vh;
        object-fit: contain;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    .button-modal {
        position: absolute;
        cursor: pointer;
        border: 0;
        padding: 0;
        background-color: rgba($color: #000000, $alpha: 0);
        transition: 0.2s;

        &:hover {
            filter: brightness(70%);
            transition: 0.2s;
        }

        &.close {
            top: 0;
            right: 0;
        }

        &.prev,
        &.next {
            bottom: 0;
            top: 0;
            height: 100px;
            margin-top: auto;
            margin-bottom: auto;
        }

        &.prev {
            left: 5px;
            transform: rotate(180deg);

            img {
                width: 6vw;
                max-width: 50px;
            }
        }

        &.next {
            right: 5px;

            img {
                width: 6vw;
                max-width: 50px;
            }
        }
    }

    .hiddeButton {
        display: none;
    }
}
