.grid-buttons {
    display: inline-flex;
    align-items: center;
}

.buttons-gallery {
    justify-content: flex-end;
}

.buttons-modal-touchpix {
    justify-content: center;
}
