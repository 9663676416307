.button-modal {
    &.qr {
        top: 2px;
        right: 25px;
        width: 20px;
        height: 20px;

        img {
            width: 100%;
        }
    }
}

.container-modal-slideshow {
    background: rgb(219, 140, 54);
    background: linear-gradient(65deg, rgba(219, 140, 54, 1) 0%, rgba(220, 83, 41, 1) 100%);
    display: flex;
    justify-content: center;
    flex-direction: column;

    .slideshow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
        overflow: hidden;

        &.full-size {
            height: 100vh;
        }

        @include sm {
            height: 70vh;
        }

        .text-no-events {
            color: #b0b0b0;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.7rem;
            font-weight: 200;
        }

        .container-media-slideshow {
            padding: 20px;
            min-height: 60vh;
            display: flex;
            justify-content: center;
            align-items: center;

            @include sm {
                padding: 0px;
            }

            .media-content {
                max-height: 70vh;
                -webkit-box-shadow: 5px 5px 13px -6px #000000;
                box-shadow: 5px 5px 13px -6px #000000;
            }
        }

        .prev-img,
        .next-img {
            filter: grayscale(80%);

            .media-content {
                max-height: 60vh;
                -webkit-box-shadow: 5px 5px 13px -6px #000000;
                box-shadow: 5px 5px 13px -6px #000000;

                @include sm {
                    max-height: 45vh;
                }
            }
        }

        .prev-img {
            transform: matrix(0.9, -0.05, 0, 1, 0, 0);
            -ms-transform: matrix(0.9, -0.05, 0, 1, 0, 0);
            -webkit-transform: matrix(0.9, -0.05, 0, 1, 0, 0);
        }

        .next-img {
            transform: matrix(0.9, 0.05, 0, 1, 0, 0);
            -ms-transform: matrix(0.9, 0.05, 0, 1, 0, 0);
            -webkit-transform: matrix(0.9, 0.05, 0, 1, 0, 0);
        }
    }

    .container-last-medias {
        box-sizing: border-box;
        height: 20vh;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 0px 15px;
        flex-direction: row;
        overflow-y: auto;

        @media (max-width: 1100px) {
            justify-content: flex-start;
        }

        @include sm {
            width: 100%;
            height: 30vh;
            padding: 15px 0px;
            background-color: rgba(#000000, 0.2);
            overflow: scroll;
            justify-content: flex-start;
            flex-direction: column;
        }

        .container-media-qr {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            background-color: #ffffff;
            border-radius: 5px;
            padding: 7px;
            -webkit-box-shadow: 5px 5px 13px -6px #000000;
            box-shadow: 5px 5px 13px -6px #000000;

            .media-qr {
                max-width: 12vh;
                height: 16vh;
                object-fit: cover;
            }
        }
    }
}
