.qr-generator {
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #232323;
    font-family: Arial, Helvetica, sans-serif;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #ffffff;
        padding: 32px;
        border-radius: 10px;
        max-width: 250px;

        h1 {
            font-size: 1.4rem;
            margin-top: 0;
            margin-bottom: 0px;
        }

        .container-input {
            margin-bottom: 15px;

            label {
                display: block;
                margin-bottom: 5px;
                font-size: 1rem;
            }

            .react-tel-input,
            &.react-tel-input {
                width: auto;

                &.email {
                    img {
                        width: 25px;
                        height: 20px;
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                    }
                }

                .form-control {
                    width: 250px;
                    padding: 10px 14px 10px 60px;
                }
            }
        }

        .form-control.error {
            border: 1px solid #e02f32;
        }

        .container-qr {
            margin-top: 20px;

            .no-qr {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 200px;
                width: 200px;
                border: 2px solid #232323;
                border-radius: 4px;

                p {
                    text-align: center;
                    font-size: 1.3rem;
                }
            }
        }

        .container-button {
            width: 100%;

            button {
                box-sizing: border-box;
                width: 100%;
                color: #fff;
                background-color: #4eacbf;
                border-radius: 5px;
                border-color: rgba(0, 0, 0, 0.05);
                height: 40px;
                margin-top: 10px;
                padding: 0px 25px;
                transition: 0.2s;
                font-size: 1.2rem;

                &:hover {
                    cursor: pointer;
                    background-color: #3e8a99;
                    transition: 0.2s;
                }

                &:active {
                    background-color: #3e8a99;
                    transition: 0s;
                }
            }
        }
    }
}
