.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}
